#google-buttons-wrapper {
    display: flex;
    justify-content: center;
    height: 500px;
}

#google-buttons-wrapper > button {
    align-self: center;
}

